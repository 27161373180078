import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.join');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-join',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.join/view.scss */
/* src/app/page.join/view.scss: no such file or directory */`],
})
export class PageJoinComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router,@Inject( ChangeDetectorRef)  private cdr: ChangeDetectorRef) {}

    public communities: any[] = [];
    public groups: any[] = [];
    public filteredGroups: any[] = [];
    public piList: any[] = [];
    public view: string = 'step1';
    public errorMessages: any = {};
    public accountNameValid: string = '';
    public accountNameChecked: boolean = false;
    public isCreatingNewGroup: boolean = false;
    public showPiSelect: boolean = false; // 승인 요청 그룹 대표 선택 표시 여부

    public data: any = {
        account_name: '',
        first_name: '',
        last_name: '',
        email: '',
        institution: '',
        department: '',
        community_id: '',
        group_id: '',
        role: 'user',
        verified: false,
        verify: false,
        onetimepass: ''
    };

    public newGroup: any = {
        name: '',
        description: ''
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/landing");
        await this.loadCommunities();
        await this.loadGroups();
        this.onRoleChange();
    }

    public async checkAccountName() {
        this.errorMessages.account_name = '';

        const lowercaseRegex = /^[a-z0-9_]+$/;
        if (!lowercaseRegex.test(this.data.account_name)) {
            this.errorMessages.account_name = '아이디는 소문자와 숫자, 언더바만 가능합니다.';
            this.accountNameChecked = false;
            this.accountNameValid = '';
            await this.service.render();
            return;
        }

        const { code, data } = await wiz.call('check_account_name', { account_name: this.data.account_name });

        if (code === 200) {
            this.accountNameValid = "사용 가능한 아이디입니다.";
            this.accountNameChecked = true;
        } else if (code === 409) {
            this.errorMessages.account_name = "이미 사용 중인 아이디입니다.";
            this.accountNameChecked = false;
        }

        await this.service.render();
    }

    public resetAccountNameCheck() {
        this.accountNameChecked = false;
        this.accountNameValid = '';
    }

    public async step(view: string) {
        this.view = view;
        await this.service.render();
    }

    public async loadCommunities() {
        const { code, data } = await wiz.call('get_communities');
        if (code === 200) {
            this.communities = data.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    public async loadGroups() {
        const { code, data } = await wiz.call('get_groups');
        if (code === 200) {
            this.groups = data;
        }
    }

    public onRoleChange() {
        // 개인 연구자일 때만 PI 선택을 보여줌
        this.showPiSelect = this.data.role === 'user';
        if (this.showPiSelect) {
            this.loadPi();
        } else {
            this.piList = [];
            this.data.pi_id = ''; // PI 선택 초기화
        }
        this.service.render();
    }


    public filterGroupsByCommunity() {
        if (this.data.community_id) {
            this.filteredGroups = this.groups.filter(group => group.service_id == this.data.community_id);
            this.filteredGroups = this.filteredGroups.filter(group => !group.name.includes('_admin') && !group.name.endsWith('_users'));

            this.data.group_id = ''; // 그룹 선택 초기화
            this.data.pi_id = ''; // PI 선택 초기화
            this.showPiSelect = this.data.role === 'user' && this.filteredGroups.length === 0;

            if (this.showPiSelect) {
                this.loadPi();
            }
        } else {
            this.filteredGroups = [];
            this.data.group_id = '';
            this.data.pi_id = '';
            this.showPiSelect = false;
        }
        this.service.render();
    }



    public async loadPi() {
        if (this.data.community_id) {
            const response = await wiz.call('getPiList', {
                community_id: this.data.community_id,
                group_id: this.data.group_id || null
            });
            if (response.code === 200) {
                this.piList = response.data;
            } else {
                this.piList = [];
            }
        }
        this.service.render();
    }

    public onGroupChange() {
        if (this.data.group_id === 'new_group') {
            this.isCreatingNewGroup = true; // 신규 그룹 생성 플래그 활성화
        } else {
            // 그룹 선택 시, 승인 요청 그룹대표 지정 초기화
            this.newGroup.name = '';
            this.newGroup.description = '';
            this.isCreatingNewGroup = false;
        }
        
        // 개인 연구자일 때만 PI 선택을 다시 로드
        this.showPiSelect = this.data.role === 'user' && !!this.data.group_id;
        if (this.showPiSelect) {
            this.loadPi();
        } else {
            this.data.pi_id = '';
        }

        this.cdr.detectChanges();
    }

    public validateGroupName() {
        if (!this.newGroup.name) {
            this.errorMessages.newGroupName = '그룹명을 입력해주세요.';
        } else {
            this.errorMessages.newGroupName = '';
        }
    }

    public async join_email_check() {
        let user = JSON.parse(JSON.stringify(this.data));
        let { code } = await wiz.call("emailCheck", user);

        if (code == 301) {
            this.data.verify = true;
            await this.emailAuthentication(this.data.email);
            await this.service.render();
            return;
        }

        await this.alert('사용할 수 없는 이메일입니다.');
    }

    public async emailAuthentication(email: string, showstatus: boolean = true) {
        await this.service.loading.show();
        let res = await wiz.call('emailAuthentication', { email });
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            await this.alert('이메일 인증 코드가 발송되었습니다', 'success');
        } else {
            await this.alert('이메일 인증 코드 발송 중 오류가 발생했습니다', 'error');
        }
    }

    public async emailVerify(email: string, onetimepass: string) {
        await this.service.loading.show();
        let res = await wiz.call('emailVerify', { email, onetimepass });
        await this.service.loading.hide();
        if (res.code === 200) {
            this.data.verified = true;
            await this.service.render();
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public async join() {
        this.errorMessages = {};

        if (!this.data.account_name) {
            this.errorMessages.account_name = '아이디를 입력해주세요.';
        } else if (!this.validateAccountName(this.data.account_name)) {
            this.errorMessages.account_name = '아이디는 소문자, 숫자, 언더바만 가능합니다.';
        } else if (!this.accountNameChecked) {
            this.errorMessages.account_name = '아이디 중복 확인을 해주세요.';
        }

        if (!this.data.first_name) {
            this.errorMessages.first_name = '이름을 입력해주세요.';
        }

        if (!this.data.last_name) {
            this.errorMessages.last_name = '성을 입력해주세요.';
        }

        if (!this.data.email) {
            this.errorMessages.email = '이메일을 입력해주세요.';
        } else if (!this.validateEmail(this.data.email)) {
            this.errorMessages.email = '올바른 이메일 형식이 아닙니다.';
        }

        if (!this.data.community_id) {
            this.errorMessages.community_id = '커뮤니티를 선택해주세요.';
        }

        if (this.filteredGroups.length > 0 && !this.data.group_id) {
            this.errorMessages.group_id = '그룹을 선택해주세요.';
        }

        if (this.data.group_id === 'new_group') {
            if (!this.newGroup.name) {
                this.errorMessages.newGroupName = '그룹명을 입력해주세요.';
            }
            if (!this.newGroup.description) {
                this.errorMessages.newGroupDescription = '그룹 설명을 입력해주세요.';
            }
        }

        if (Object.keys(this.errorMessages).length > 0) {
            await this.service.render();
            return;
        }

        await this.service.loading.show();

        try {
            let account_name = this.data.account_name;
            let raw_password = account_name + "1234";
            this.data.password = this.service.auth.hash(raw_password);
            let user = JSON.parse(JSON.stringify(this.data));
            user.full_name = `${user.first_name} ${user.last_name}`;

            if (this.data.group_id === 'new_group') {
                user.new_group_name = `${this.getCommunityName(this.data.community_id)}_users_${this.newGroup.name}`;
                user.new_group_description = this.newGroup.description;
                user.request_type = 'N';
            } else {
                user.request_type = 'S';
            }

            const { code } = await wiz.call("join", user);
            if (code === 200) {
                this.service.alert.success(`${account_name} 님 GSDC 계정 생성을 위한 요청이 완료되었습니다.`);
                await this.router.navigate(['/login']);
            } else {
                this.service.alert.error("회원가입 요청에 실패했습니다.");
            }
        } catch (error) {
            console.error('회원가입 중 오류 발생:', error);
            this.service.alert.error("회원가입 처리 중 오류가 발생했습니다.");
        } finally {
            await this.service.loading.hide();
        }
    }

    public getCommunityName(community_id: string): string {
        const community = this.communities.find(c => c.id == community_id);
        return community ? community.name.toLowerCase() : '';
    }

    public validateAccountName(account_name: string): boolean {
        const regex = /^[a-z0-9_]+$/;
        return regex.test(account_name);
    }

    public validateEmail(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
}

export default PageJoinComponent;