import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.group.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-gsdcsysuser-group-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.group.list/view.scss */
/* src/app/portal.gsdcsysuser.group.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserGroupListComponent implements OnInit {

    public groups: any = [];
    public editors: any = [];

    @ViewChild('editor')
    public editorElement: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
        // await this.buildEditor();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code != 200) return;
        this.groups = data;

        await this.service.render();
        for (let i = 0; i < this.groups.length; i++) {
            // const EDITOR_ID = `textarea#editor`;
            await this.buildEditor(`#editor${i}`, i);
        }
        await this.service.render();
    }

    public async buildEditor(EDITOR_ID: any, index: any) {
        // let editor = this.editor = await ClassicEditor.create(document.querySelector(EDITOR_ID), {
        // let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {

        const element = document.querySelector(EDITOR_ID);  // 선택자를 사용해 textarea를 가져옴

        if (!element) {
            console.error(`Editor element not found: ${EDITOR_ID}`);
            return;
        }

        let editor = await ClassicEditor.create(element, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        editor.ui.view.editable.element.classList.add('border-0');
        editor.data.set(this.groups[index].description);

        await this.service.render();
    }
}

export default PortalGsdcsysuserGroupListComponent;