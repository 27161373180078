import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.approve.signup.wgroup');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-approve-signup-wgroup',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.approve.signup.wgroup/view.scss */
/* src/app/portal.gsdcsysuser.approve.signup.wgroup/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserApproveSignupWgroupComponent implements OnInit {
    public signupRequests: any[] = [];
    public loading: boolean = false;
    public pagedRequests: any[] = [];
    public role: string = '';
    public community_id: string = '';

    public pagination: any = {
        current: 1,  // 현재 페이지
        start: 1,    // 시작 페이지
        end: 1       // 총 페이지 수
    };
    
    public search = {
        dump: 10 // 페이지당 요청 수
    };

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        const session = await wiz.call("get_session");
        this.role = session.data.role;
        this.community_id = session.data.community_id;

        await this.loadSignupRequests();
    }

    async loadSignupRequests() {
        this.loading = true;
        const { code, data } = await wiz.call('get_pending_requests', { type: 'S' });
        if (code === 200) {
            this.signupRequests = data;
            this.calculatePagination(); // 총 페이지 수 계산
        }
        this.loading = false;
        await this.service.render();
    }
    
    // 페이지네이션 계산 함수
    calculatePagination() {
        const totalRequests = this.signupRequests.length;
        this.pagination.end = Math.ceil(totalRequests / this.search.dump); // 총 페이지 수 설정
        this.changePage(1); // 첫 페이지로 초기화
    }

    // 현재 페이지의 데이터를 업데이트하는 함수
    updatePagedRequests() {
        const startIdx = (this.pagination.current - 1) * this.search.dump;
        const endIdx = startIdx + this.search.dump;
        this.pagedRequests = this.signupRequests.slice(startIdx, endIdx);
    }

    // 페이지 변경 함수
    changePage(page: number) {
        if (page >= this.pagination.start && page <= this.pagination.end) {
            this.pagination.current = page;
            this.updatePagedRequests();
        }
    }


    canApprove(request: any): boolean {
        return (this.role === 'pi' && request.level_1 === 'N') ||
            (this.role === 'leader' && request.level_2 === 'N') ||
            (this.role === 'manager' && request.level_3 === 'N' && request.status !== 'D');
    }

    canReject(request: any): boolean {
        return this.canApprove(request);  // 거절은 승인 가능 조건과 동일하게 설정
    }

    async approveRequest(requestId: string) {
        const isConfirmed = confirm('승인하시겠습니까?');
        if (!isConfirmed) return;

        this.service.loading.show();
        const { code } = await wiz.call('approve_request', { request_id: requestId });
        this.service.loading.hide();

        if (code === 200) {
            await this.service.alert.success('요청이 승인되었습니다.');
            await this.loadSignupRequests();
        } else {
            await this.service.alert.error('승인 중 오류가 발생했습니다.');
        }
    }

    async rejectRequest(requestId: string) {
        const isConfirmed = confirm('거절하시겠습니까?');
        if (!isConfirmed) return;

        this.loading = true;
        const { code } = await wiz.call('reject_request', { request_id: requestId });
        this.loading = false;

        if (code === 200) {
            await this.service.alert.success('요청이 거절되었습니다.');
            await this.loadSignupRequests();
        } else {
            await this.service.alert.error('거절 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }
}

export default PortalGsdcsysuserApproveSignupWgroupComponent;