import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.reset.password');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-reset-password',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.reset.password/view.scss */
/* src/app/page.reset.password/view.scss: no such file or directory */`],
})
export class PageResetPasswordComponent implements OnInit {
    public errorMessages: any = {}; // 오류 메시지를 담는 객체
    
    public data: any = {
        new_password: '',
        confirm_password: '',
    };
    

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }
    
    public validatePassword(password: string): boolean {
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?~-]).{9,}$/;
        return regex.test(password);
    }
    
    public async resetPassword() {
        this.errorMessages = {};
        // 비밀번호 확인
        if (!this.data.new_password) {
            this.errorMessages.new_password = '새 비밀번호를 입력해주세요.';
        } else if (!this.validatePassword(this.data.new_password)) {
            this.errorMessages.new_password = '비밀번호는 9자리 이상, 숫자, 문자, 특수문자를 포함해야 합니다.';
        }

        if (!this.data.confirm_password) {
            this.errorMessages.confirm_password = '비밀번호 확인을 입력해주세요.';
        } else if (this.data.new_password !== this.data.confirm_password) {
            this.errorMessages.confirm_password = '비밀번호가 일치하지 않습니다.';
        }


        if (Object.keys(this.errorMessages).length > 0) {
            // 오류 메시지가 있을 경우 UI 업데이트 후 중단
            await this.service.render();
            return;
        }

        // 비밀번호 재설정 데이터 생성
        let hashed_password = this.service.auth.hash(this.data.new_password);

        let requestData = {
            new_password: this.data.new_password,
            hashed_password: hashed_password
        };

        this.service.loading.show(); // 로딩 시작

        // 비밀번호 재설정 API 호출
        let { code, data } = await wiz.call("reset_password", requestData);

        this.service.loading.hide(); // 로딩 종료

        if (code == 200) {
            if (data.otp_issuance === 'N') {
                await this.alert("비밀번호가 성공적으로 재설정되었습니다. OTP 발급 페이지로 이동합니다.", "success");
                location.href = '/otp/issuance'; // OTP 발급 페이지로 이동
            } else {
                await this.alert("비밀번호가 성공적으로 재설정되었습니다. 로그인 페이지로 이동합니다.", "success");
                location.href = '/login'; // 로그인 페이지로 이동
            }
        } else {
            await this.alert(data, "error");
        }
    }
}

export default PageResetPasswordComponent;