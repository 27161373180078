import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.widget.community.group.setting');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import { Router } from '@angular/router'

@Component({
    selector: 'wiz-portal-gsdcsysadmin-widget-community-group-setting',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.widget.community.group.setting/view.scss */
/* src/app/portal.gsdcsysadmin.widget.community.group.setting/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminWidgetCommunityGroupSettingComponent implements OnInit, OnDestroy {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    @Input() group: any;
    @Input() community: any;

    public async ngOnInit() {
        await this.service.init();
        await this.buildEditor();
    }

    public async buildEditor() {
        let element: any = this.editorElement.nativeElement;
        let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

        this.editor = await ClassicEditor.create(element, {
            toolbar: {
                items: toolbar,
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        this.editor.data.set(this.group.description);

        await this.service.render();
    }

    public async ngOnDestroy() {
        this.group.description = this.editor.data.get();
    }

    public async update() {
        this.group.description = this.editor.data.get();
        if (this.group.name == "") return await this.service.alert.error("연구 그룹 명을 입력해주세요.");
        if (!('id' in this.group)) this.group.service_id = this.community.id;
        const { code } = await wiz.call("update", this.group);
        if (code != 200) await this.service.alert.error("오류가 발생했습니다.");
        else {
            let res = await this.service.alert.success("저장되었습니다.");
            if (res && !('id' in this.group)) await this.router.navigate(['/admin/community', this.community.id, 'group']);
        }
        await this.service.render();
    }

    public async delete() {
        let res1 = await this.service.alert.error("해당 연구그룹을 삭제하시겠습니까?", "취소")
        if (!res1) return;
        const { code, data } = await wiz.call("delete", this.group);
        if (code!=200) await this.service.alert.error("오류가 발생했습니다.");
        else {
            let res = await this.service.alert.success("삭제되었습니다.");
            if (res) await this.router.navigate(['/admin/community', this.community.id, 'group']);
        }
    }
}

export default PortalGsdcsysadminWidgetCommunityGroupSettingComponent;