import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.widget.group.info');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-gsdcsysuser-widget-group-info',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.widget.group.info/view.scss */
/* src/app/portal.gsdcsysuser.widget.group.info/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserWidgetGroupInfoComponent implements OnInit, OnDestroy {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service) public service: Service) { }

    @Input() community: any;
    @Input() group: any;

    public async ngOnInit() {
        await this.service.init();

        let element: any = this.editorElement.nativeElement;
        let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

        this.editor = await ClassicEditor.create(element, {
            toolbar: {
                items: toolbar,
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = this.editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        this.editor.isReadOnly = true;

        this.editor.data.set(this.group.description);
    }
}

export default PortalGsdcsysuserWidgetGroupInfoComponent;