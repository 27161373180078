import { Inject } from '@angular/core';
import '@angular/compiler';
import { PageSupportComponent } from './page.support/page.support.component';
import { PortalGsdcsysadminWidgetUserProfileComponent } from './portal.gsdcsysadmin.widget.user.profile/portal.gsdcsysadmin.widget.user.profile.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalGsdcsysadminCommunityListComponent } from './portal.gsdcsysadmin.community.list/portal.gsdcsysadmin.community.list.component';
import { PortalGsdcsysuserManualListComponent } from './portal.gsdcsysuser.manual.list/portal.gsdcsysuser.manual.list.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalGsdcsysuserBoardListComponent } from './portal.gsdcsysuser.board.list/portal.gsdcsysuser.board.list.component';
import { PortalGsdcsysadminWidgetCommunityGroupInfoComponent } from './portal.gsdcsysadmin.widget.community.group.info/portal.gsdcsysadmin.widget.community.group.info.component';
import { PortalGsdcsysuserTicketingListComponent } from './portal.gsdcsysuser.ticketing.list/portal.gsdcsysuser.ticketing.list.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageReissueOtpComponent } from './page.reissue.otp/page.reissue.otp.component';
import { PortalGsdcsysadminWidgetCommunityTicketingListComponent } from './portal.gsdcsysadmin.widget.community.ticketing.list/portal.gsdcsysadmin.widget.community.ticketing.list.component';
import { PortalGsdcsysuserGroupNewComponent } from './portal.gsdcsysuser.group.new/portal.gsdcsysuser.group.new.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalGsdcsysuserBoardItemComponent } from './portal.gsdcsysuser.board.item/portal.gsdcsysuser.board.item.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalGsdcsysadminWidgetCommunityTicketingItemComponent } from './portal.gsdcsysadmin.widget.community.ticketing.item/portal.gsdcsysadmin.widget.community.ticketing.item.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { PortalGsdcsysuserApproveGroupNewComponent } from './portal.gsdcsysuser.approve.group.new/portal.gsdcsysuser.approve.group.new.component';
import { PortalGsdcsysuserApproveGroupJoinComponent } from './portal.gsdcsysuser.approve.group.join/portal.gsdcsysuser.approve.group.join.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityInfoEditComponent } from './portal.gsdcsysadmin.widget.community.info.edit/portal.gsdcsysadmin.widget.community.info.edit.component';
import { PortalGsdcsysuserWidgetGroupInfoComponent } from './portal.gsdcsysuser.widget.group.info/portal.gsdcsysuser.widget.group.info.component';
import { PortalGsdcsysuserResearchListComponent } from './portal.gsdcsysuser.research.list/portal.gsdcsysuser.research.list.component';
import { PortalGsdcsysuserFaqListComponent } from './portal.gsdcsysuser.faq.list/portal.gsdcsysuser.faq.list.component';
import { PageResetPasswordComponent } from './page.reset.password/page.reset.password.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalGsdcsysuserGroupUserComponent } from './portal.gsdcsysuser.group.user/portal.gsdcsysuser.group.user.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PortalGsdcsysuserGroupItemComponent } from './portal.gsdcsysuser.group.item/portal.gsdcsysuser.group.item.component';
import { PortalGsdcsysadminWidgetCommunityUserComponent } from './portal.gsdcsysadmin.widget.community.user/portal.gsdcsysadmin.widget.community.user.component';
import { ComponentDropdownComponent } from './component.dropdown/component.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityGroupUserComponent } from './portal.gsdcsysadmin.widget.community.group.user/portal.gsdcsysadmin.widget.community.group.user.component';
import { PageGroupIdSubComponent } from './page.group.id.sub/page.group.id.sub.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PageResearchIdComponent } from './page.research.id/page.research.id.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { PortalGsdcsysadminWidgetCommunityGroupItemComponent } from './portal.gsdcsysadmin.widget.community.group.item/portal.gsdcsysadmin.widget.community.group.item.component';
import { PortalGsdcsysadminWidgetCommunitySettingComponent } from './portal.gsdcsysadmin.widget.community.setting/portal.gsdcsysadmin.widget.community.setting.component';
import { PageReissueBothComponent } from './page.reissue.both/page.reissue.both.component';
import { PageUserComponent } from './page.user/page.user.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalGsdcsysuserApproveSignupComponent } from './portal.gsdcsysuser.approve.signup/portal.gsdcsysuser.approve.signup.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalGsdcsysadminRequestListComponent } from './portal.gsdcsysadmin.request.list/portal.gsdcsysadmin.request.list.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalGsdcsysuserWidgetResearchGraphComponent } from './portal.gsdcsysuser.widget.research.graph/portal.gsdcsysuser.widget.research.graph.component';
import { PortalGsdcsysadminFaqListComponent } from './portal.gsdcsysadmin.faq.list/portal.gsdcsysadmin.faq.list.component';
import { PortalGsdcsysadminBoardItemComponent } from './portal.gsdcsysadmin.board.item/portal.gsdcsysadmin.board.item.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { PortalGsdcsysuserGroupListComponent } from './portal.gsdcsysuser.group.list/portal.gsdcsysuser.group.list.component';
import { PageReissuePasswordComponent } from './page.reissue.password/page.reissue.password.component';
import { PortalGsdcsysuserRequestListComponent } from './portal.gsdcsysuser.request.list/portal.gsdcsysuser.request.list.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalGsdcsysadminTicketingListComponent } from './portal.gsdcsysadmin.ticketing.list/portal.gsdcsysadmin.ticketing.list.component';
import { PortalGsdcsysadminWidgetCommunityBoardItemComponent } from './portal.gsdcsysadmin.widget.community.board.item/portal.gsdcsysadmin.widget.community.board.item.component';
import { PortalGsdcsysadminUserAddComponent } from './portal.gsdcsysadmin.user.add/portal.gsdcsysadmin.user.add.component';
import { PortalGsdcsysadminWidgetManualDriveComponent } from './portal.gsdcsysadmin.widget.manual.drive/portal.gsdcsysadmin.widget.manual.drive.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalGsdcsysuserWidgetManualDriveComponent } from './portal.gsdcsysuser.widget.manual.drive/portal.gsdcsysuser.widget.manual.drive.component';
import { PortalGsdcsysadminWidgetCommunityGroupListComponent } from './portal.gsdcsysadmin.widget.community.group.list/portal.gsdcsysadmin.widget.community.group.list.component';
import { PortalGsdcsysadminResearchItemComponent } from './portal.gsdcsysadmin.research.item/portal.gsdcsysadmin.research.item.component';
import { PortalGsdcsysadminManualListComponent } from './portal.gsdcsysadmin.manual.list/portal.gsdcsysadmin.manual.list.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalGsdcsysadminWidgetCommunityGroupSettingComponent } from './portal.gsdcsysadmin.widget.community.group.setting/portal.gsdcsysadmin.widget.community.group.setting.component';
import { PortalGsdcsysuserApproveSignupWgroupComponent } from './portal.gsdcsysuser.approve.signup.wgroup/portal.gsdcsysuser.approve.signup.wgroup.component';
import { PortalGsdcsysadminResearchListComponent } from './portal.gsdcsysadmin.research.list/portal.gsdcsysadmin.research.list.component';
import { PortalGsdcsysuserTicketingItemComponent } from './portal.gsdcsysuser.ticketing.item/portal.gsdcsysuser.ticketing.item.component';
import { PortalGsdcsysadminTicketingItemComponent } from './portal.gsdcsysadmin.ticketing.item/portal.gsdcsysadmin.ticketing.item.component';
import { PageBoardIdComponent } from './page.board.id/page.board.id.component';
import { PageOtpIssuanceComponent } from './page.otp.issuance/page.otp.issuance.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';
import { PortalGsdcsysadminCommunityItemComponent } from './portal.gsdcsysadmin.community.item/portal.gsdcsysadmin.community.item.component';
import { PortalGsdcsysuserGroupJoinComponent } from './portal.gsdcsysuser.group.join/portal.gsdcsysuser.group.join.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PageRequestIdComponent } from './page.request.id/page.request.id.component';
import { PortalGsdcsysadminWidgetCommunityBoardListComponent } from './portal.gsdcsysadmin.widget.community.board.list/portal.gsdcsysadmin.widget.community.board.list.component';
import { PortalGsdcsysuserFaqItemComponent } from './portal.gsdcsysuser.faq.item/portal.gsdcsysuser.faq.item.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalGsdcsysuserResearchItemComponent } from './portal.gsdcsysuser.research.item/portal.gsdcsysuser.research.item.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { PortalGsdcsysuserWidgetGroupUserComponent } from './portal.gsdcsysuser.widget.group.user/portal.gsdcsysuser.widget.group.user.component';
import { PortalGsdcsysadminBoardListComponent } from './portal.gsdcsysadmin.board.list/portal.gsdcsysadmin.board.list.component';
import { PortalGsdcsysadminFaqItemComponent } from './portal.gsdcsysadmin.faq.item/portal.gsdcsysadmin.faq.item.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalGsdcsysadminUserListComponent } from './portal.gsdcsysadmin.user.list/portal.gsdcsysadmin.user.list.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(@Inject( DomSanitizer) private domSanitizer: DomSanitizer) { }
    transform(url) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@NgModule({
    declarations: [
        SafePipe,
        AppComponent,
        PageSupportComponent,
        PortalGsdcsysadminWidgetUserProfileComponent,
        PortalSeasonAlertComponent,
        PortalGsdcsysadminCommunityListComponent,
        PortalGsdcsysuserManualListComponent,
        PageDashboardComponent,
        PortalGsdcsysuserBoardListComponent,
        PortalGsdcsysadminWidgetCommunityGroupInfoComponent,
        PortalGsdcsysuserTicketingListComponent,
        PageJoinComponent,
        PortalDizestEditorTerminalComponent,
        PageReissueOtpComponent,
        PortalGsdcsysadminWidgetCommunityTicketingListComponent,
        PortalGsdcsysuserGroupNewComponent,
        PortalDizestEditorVideoComponent,
        PortalGsdcsysuserBoardItemComponent,
        PortalDizestEditorSettingComponent,
        PortalGsdcsysadminWidgetCommunityTicketingItemComponent,
        PortalDizestEditorWorkflowSidebarInfoComponent,
        PortalGsdcsysuserApproveGroupNewComponent,
        PortalGsdcsysuserApproveGroupJoinComponent,
        PortalDizestDriveComponent,
        PortalSeasonUiDropdownComponent,
        PortalGsdcsysadminWidgetCommunityInfoEditComponent,
        PortalGsdcsysuserWidgetGroupInfoComponent,
        PortalGsdcsysuserResearchListComponent,
        PortalGsdcsysuserFaqListComponent,
        PageResetPasswordComponent,
        PortalSeasonStatusbarComponent,
        PortalGsdcsysuserGroupUserComponent,
        ComponentNavSidebarComponent,
        LayoutSidebarComponent,
        PortalGsdcsysuserGroupItemComponent,
        PortalGsdcsysadminWidgetCommunityUserComponent,
        ComponentDropdownComponent,
        PortalGsdcsysadminWidgetCommunityGroupUserComponent,
        PageGroupIdSubComponent,
        PageMypageComponent,
        PortalDizestEditorWorkflowComponent,
        PageResearchIdComponent,
        PortalDizestEditorWorkflowNodeComponent,
        PortalGsdcsysadminWidgetCommunityGroupItemComponent,
        PortalGsdcsysadminWidgetCommunitySettingComponent,
        PageReissueBothComponent,
        PageUserComponent,
        PortalSeasonViewerTreeComponent,
        PortalGsdcsysuserApproveSignupComponent,
        LayoutEmptyComponent,
        PortalGsdcsysadminRequestListComponent,
        PortalSeasonLoadingComponent,
        PortalGsdcsysuserWidgetResearchGraphComponent,
        PortalGsdcsysadminFaqListComponent,
        PortalGsdcsysadminBoardItemComponent,
        PortalDizestEditorWorkflowSidebarAiComponent,
        PortalGsdcsysuserGroupListComponent,
        PageReissuePasswordComponent,
        PortalGsdcsysuserRequestListComponent,
        PortalDizestEditorImageComponent,
        PageLoginComponent,
        PortalSeasonTabComponent,
        PortalGsdcsysadminTicketingListComponent,
        PortalGsdcsysadminWidgetCommunityBoardItemComponent,
        PortalGsdcsysadminUserAddComponent,
        PortalGsdcsysadminWidgetManualDriveComponent,
        PortalDizestEditorWorkflowSidebarApiComponent,
        PortalGsdcsysuserWidgetManualDriveComponent,
        PortalGsdcsysadminWidgetCommunityGroupListComponent,
        PortalGsdcsysadminResearchItemComponent,
        PortalGsdcsysadminManualListComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonLoadingHexaComponent,
        PortalGsdcsysadminWidgetCommunityGroupSettingComponent,
        PortalGsdcsysuserApproveSignupWgroupComponent,
        PortalGsdcsysadminResearchListComponent,
        PortalGsdcsysuserTicketingItemComponent,
        PortalGsdcsysadminTicketingItemComponent,
        PageBoardIdComponent,
        PageOtpIssuanceComponent,
        PageCommunityComponent,
        PortalDizestEditorDefaultComponent,
        PortalDizestEditorWorkflowSidebarPipComponent,
        PortalDizestEditorWorkflowSidebarCodeComponent,
        PortalGsdcsysadminCommunityItemComponent,
        PortalGsdcsysuserGroupJoinComponent,
        PortalSeasonPaginationComponent,
        PageRequestIdComponent,
        PortalGsdcsysadminWidgetCommunityBoardListComponent,
        PortalGsdcsysuserFaqItemComponent,
        PageAdminComponent,
        PortalGsdcsysuserResearchItemComponent,
        PortalDizestEditorHealthComponent,
        PortalGsdcsysuserWidgetGroupUserComponent,
        PortalGsdcsysadminBoardListComponent,
        PortalGsdcsysadminFaqItemComponent,
        LayoutNavbarComponent,
        PortalGsdcsysadminUserListComponent,
        PortalDizestEditorWorkflowSidebarAppsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }