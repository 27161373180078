import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.manual.list');
import { OnInit } from "@angpllar/core";
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Dizest } from "src/libs/portal/dizest/dizest";

import DefaultEditor from "src/app/portal.dizest.editor.default/portal.dizest.editor.default.component";

import showdown from 'showdown';
import { KeyMod, KeyCode } from 'monaco-editor';

@Component({
    selector: 'wiz-portal-gsdcsysuser-manual-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.manual.list/view.scss */
/* src/app/portal.gsdcsysuser.manual.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserManualListComponent implements OnInit {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public workspace: any;
    public shortcuts: any = [];

    public loaded: boolean = false;

    public monaco: any = {
        value: '',
        language: 'markdown',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }
    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public ref: ViewContainerRef    ) {
        this.dizest = new Dizest(service);

        this.dizest.editorType.bind({
            cls: DefaultEditor,
            trigger: (ext, node) => {
                return true;
            }
        });

        this.shortcuts.push({
            name: "save",
            key: ["cmd + s", "ctrl + s"],
            monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
            preventDefault: true,
            command: async () => {
                await this.save();
            }
        });
    }

    public async ngOnInit() {
        await this.service.init();
        await this.dizest.kernels();
        await this.dizest.loadConfig();
        await this.service.render();

        let extension: any = "md";
        let viewtypes: any = {
            'sql': { language: 'sql' },
            'md': { language: 'markdown' },
            'ts': { language: 'typescript', renderValidationDecorations: 'off' },
            'js': { language: 'javascript' },
            'css': { language: 'css' },
            'scss': { language: 'scss' },
            'json': { language: 'json' },
            'pug': { language: 'pug' },
            'py': { language: 'python' }
        };

        this.monaco = {
            value: '',
            language: 'markdown',
            theme: "vs",
            fontSize: 14,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            wordWrap: true,
            roundedSelection: false,
            glyphMargin: false,
            scrollbar: {
                handleMouseWheel: true,
            },
            minimap: {
                enabled: false
            }
        };

        if (viewtypes[extension])
            for (let key in viewtypes[extension])
                this.monaco[key] = viewtypes[extension][key];

        this.loaded = true;
        await this.service.render();
    }

    public async init(e: any) {
        if (this.shortcuts) {
            for (let i = 0; i < this.shortcuts.length; i++) {
                let shortcut = this.shortcuts[i];
                e.editor.addCommand(shortcut.monaco, shortcut.command);
            }
        }
    }

    public filter: any;
    public content: string = "";
    public descriptionEditable: boolean = false;
    public tabs: any = [];
    public filename: any;

    public onContentChanged(event: any) {
        this.content = event; // 변경된 내용을 tab에 반영
        this.service.render();
    }

    public async toggleEditable() {
        this.descriptionEditable = !this.descriptionEditable;
        await this.service.render();
    }
    public showdown(text) {
        const markdown = typeof text === 'string' ? text : '';
        let converter = new showdown.Converter();
        return converter.makeHtml(markdown);
    }

    public tabInfo: any = [];
    public current: any = {};
    public mode: any;
    public async info(e) {
        let filter = JSON.stringify(e);
        const { code, data } = await wiz.call("load", { filter: filter });
        if (code == 200) {
            this.content = data.data;
            this.filename = data.name;
            this.mode = data.mode;

            if (!this.tabs.includes(this.filename)) this.tabs.push(this.filename);
            if (!this.tabInfo.some(item => item.name === this.filename)) this.tabInfo.push({ "name": this.filename, "content": this.content, "mode": this.mode });
            this.current = { "name": this.filename, "content": this.content, "mode": this.mode };
            await this.service.render();
        }
    }

    public async change(tab: any) {
        this.current = tab;
        this.descriptionEditable = false;
        await this.service.render();
    }

    public async close(tab: any) {
        this.tabs = this.tabs.filter(tag => tag !== tab.name);
        this.tabInfo = this.tabInfo.filter(item => item.name !== tab.name);
        this.descriptionEditable = false;
        if (this.tabInfo.length > 0) {
            this.current = this.tabInfo[this.tabInfo.length - 1];
        } else {
            this.current = {}; // 배열이 비어 있을 경우 처리
        }
        await this.service.render();
    }

    public workspaceSortableOption: any = {
        animation: 0,
        handle: '.view-tab'
    };

    public editorLoading: boolean = false;

    public driveConfig: any = {
        open: async (node, ext) => {
            this.editorLoading = true;
            await this.service.render();
            let opts: any = this.dizest.editorType.select(ext, node);
            opts.extension = ext;
            // await this.workspace.open(node.id, opts);
            await this.dizest.kernels();
            this.editorLoading = false;
            await this.service.render();
            return true;
        }
    };

    public isHideDrive: boolean = false;

    public chatbotUrl() {
        return 'https://gsdc-llm.seasonsoft.net/?page=' + this.service.auth.session.job_title;
    }
}

export default PortalGsdcsysuserManualListComponent;