import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.mypage/view.scss */
/* src/app/page.mypage/view.scss: no such file or directory */`],
})
export class PageMypageComponent implements OnInit {
    public user: any = {
        account_name: '',
        full_name: '',
        institution: '',
        department: '',
        job_title: '',
        email: '',
        my_group: ''
    };

    public qrCodeImage: string | null = null;
    public otpPassword: string = '';
    public currentPassword: string = '';
    public errorMessages: any = {};
    public isVerified: boolean = false;
    public isOtpVerified: boolean = false;
    public activeTab: string = 'info';

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.loadUserInfo();
    }

    public async alert(message: string, status: string = 'error') {
        const cancelButton = status === 'warning' ? "취소" : null;

        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancelButton,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

    public async verifyPassword() {
        if (!this.currentPassword) {
            this.errorMessages.currentPassword = '현재 비밀번호를 입력해주세요.';
            return;
        }

        this.service.loading.show();
        const { code, message } = await wiz.call("verify_password", { password: this.currentPassword });
        this.service.loading.hide();

        if (code === 200) {
            this.isVerified = true;
            await this.alert("비밀번호 인증 성공", "success");
        } else {
            this.isVerified = false;
            await this.alert(message || "비밀번호 인증 실패", "error");
        }
    }

    public async confirmPasswordReset() {
        const confirm = await this.alert("비밀번호를 초기화하시겠습니까?", "warning");
        if (!confirm) return;

        this.service.loading.show();
        const { code, message } = await wiz.call("reset_password");
        this.service.loading.hide();

        if (code === 200) {
            await this.alert(
                "비밀번호 초기화가 완료되었습니다.\n초기 비밀번호는 ID + 1234 입니다.\n재접속하여 비밀번호 재설정을 진행해주세요",
                "success"
            );
            this.isVerified = false;
            window.location.href = '/auth/logout';
        } else {
            await this.alert(message || "비밀번호 초기화 실패", "error");
        }
    }

    public async verifyOtpPassword() {
        if (!this.otpPassword) {
            this.errorMessages.otpPassword = '현재 비밀번호를 입력해주세요.';
            return;
        }

        this.service.loading.show();
        const { code, message } = await wiz.call("verify_password", { password: this.otpPassword });
        this.service.loading.hide();

        if (code === 200) {
            this.isOtpVerified = true;
            await this.alert("비밀번호 인증 성공", "success");
        } else {
            this.isOtpVerified = false;
            await this.alert(message || "비밀번호 인증 실패", "error");
        }
    }

    public async confirmOtpReissue() {
        const confirm = await this.alert("OTP를 초기화하시겠습니까?", "warning");
        if (!confirm) return;        
        if (!this.isOtpVerified) return;

        this.service.loading.show();
        const { code, data, message } = await wiz.call("reissue_otp");
        this.service.loading.hide();

        if (code === 200) {
            this.qrCodeImage = data.qr_code_image;
            await this.alert(
                "OTP 초기화가 완료되었습니다.\n재접속하여 OTP 재발급을 진행해주세요",
                "success"
            );
            this.isOtpVerified = false;
            window.location.href = '/auth/logout';
        } else {
            await this.alert(message || "OTP 재발급 실패", "error");
        }
    }

    public setTab(tab: string) {
        this.activeTab = tab;
        if (tab === 'password') {
            // 비밀번호 초기화 탭 선택 시 초기화
            this.isVerified = false;
            this.currentPassword = '';
            this.errorMessages.currentPassword = null;
        } else if (tab === 'otp') {
            // OTP 초기화 탭 선택 시 초기화
            this.isOtpVerified = false;
            this.otpPassword = '';
            this.qrCodeImage = null;
            this.errorMessages.otpPassword = null;
        }
    }

    public async loadUserInfo() {
        this.service.loading.show();
        let { code, data } = await wiz.call("get_user_info");
        this.service.loading.hide();

        if (code === 200) {
            this.user = data.user;
        } else {
            this.service.alert.error("사용자 정보를 불러오는 데 실패했습니다.");
        }
    }

    public async saveUserInfo() {
        this.service.loading.show();
        let { code } = await wiz.call("update_user_info", this.user);
        this.service.loading.hide();

        if (code === 200) {
            this.service.alert.success("기본 정보가 성공적으로 업데이트되었습니다.");
        } else {
            this.service.alert.error("기본 정보 업데이트에 실패했습니다.");
        }
    }
}

export default PageMypageComponent;