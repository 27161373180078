import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.user.add');
import { OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-user-add',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.user.add/view.scss */
/* src/app/portal.gsdcsysadmin.user.add/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminUserAddComponent implements OnInit {
    @Input() view: any;
    public communities: any[] = [];
    public groups: any[] = [];
    public filteredGroups: any[] = [];
    public errorMessages: any = {}; // 오류 메시지를 담는 객체
    public accountNameValid: string = '';  // 아이디 중복 확인 메시지
    public accountNameChecked: boolean = false;  // 중복 확인 여부 플래그

    public user: any = {
        account_name: '',
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        institution: '',
        department: '',
        community_id: '',
        group_id: '',
        role: 'user',
        group_ids: []
    };

    public errorMessages: any = {
        account_name: '',
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        community_id: '',
        group_id: ''
    };

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.view === "admin") await this.service.auth.allow.membership('admin', "/landing");
        else await this.service.auth.allow(true, "/landing");

        await this.loadCommunities();
        await this.loadGroups();
    }

    public async checkAccountName() {
        this.errorMessages.account_name = '';

        // 아이디 소문자, 숫자, 언더바 검사
        const lowercaseRegex = /^[a-z0-9_]+$/;
        if (!lowercaseRegex.test(this.user.account_name)) {
            this.errorMessages.account_name = '아이디는 소문자와 숫자, 언더바만 가능합니다.';
            this.accountNameChecked = false;
            this.accountNameValid = '';  // 성공 메시지 초기화
            await this.service.render();  // 오류 메시지 표시
            return;  // 형식이 맞지 않으면 중복 확인 중단
        }

        // 중복 확인 요청 진행
        const { code, data } = await wiz.call('check_account_name', { account_name: this.user.account_name });

        if (code === 200) {
            this.accountNameValid = "사용 가능한 아이디입니다.";
            this.accountNameChecked = true;  // 중복 확인 완료 플래그 설정
        } else if (code === 409) {
            this.errorMessages.account_name = "이미 사용 중인 아이디입니다.";
            this.accountNameChecked = false;  // 중복 확인 실패
        }

        await this.service.render();
    }


    public resetAccountNameCheck() {
        this.accountNameChecked = false;  // 사용자가 아이디를 수정할 경우 중복 확인 플래그 초기화
        this.accountNameValid = '';  // 성공 메시지 초기화
    }

    public async loadCommunities() {
        const { code, data } = await wiz.call('get_communities');
        if (code === 200) {
            this.communities = data;
        }
    }

    public async loadGroups() {
        const { code, data } = await wiz.call('get_groups');
        if (code === 200) {
            this.groups = data;
        }
    }

    public filterGroupsByCommunity() {
        if (this.user && this.user.community_id) {
            this.filteredGroups = this.groups.filter(group => group.service_id == this.user.community_id);

            this.filteredGroups = this.filteredGroups.filter(group =>
                !group.name.includes('_admin') && !group.name.endsWith('_users')
            );

            this.user.group_id = '';
        } else {
            this.filteredGroups = [];
            this.user.group_id = '';
        }

        this.service.render();
    }

    public validateInputs() {
        let valid = true;

        this.errorMessages = {
            account_name: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            community_id: '',
            group_id: ''
        };

        // 아이디 소문자 검사
        const lowercaseRegex = /^[a-z0-9_]+$/;
        if (!this.user.account_name) {
            this.errorMessages.account_name = '아이디를 입력해주세요.';
            valid = false;
        } else if (!lowercaseRegex.test(this.user.account_name)) {
            this.errorMessages.account_name = '아이디는 소문자와 숫자, 언더바만 가능합니다.';
            valid = false;
        }

        if (!this.user.first_name) {
            this.errorMessages.first_name = '이름을 입력해주세요.';
            valid = false;
        }

        if (!this.user.last_name) {
            this.errorMessages.last_name = '성을 입력해주세요.';
            valid = false;
        }

        // 이메일 유효성 검사
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.user.email) {
            this.errorMessages.email = '이메일을 입력해주세요.';
            valid = false;
        } else if (!emailRegex.test(this.user.email)) {
            this.errorMessages.email = '유효한 이메일을 입력해주세요.';
            valid = false;
        }

        if (!this.user.role) {
            this.errorMessages.role = '권한을 선택해주세요.';
            valid = false;
        }

        if (!this.user.community_id) {
            this.errorMessages.community_id = '커뮤니티를 선택해주세요.';
            valid = false;
        }

        // 그룹 선택 유효성 검사 - 그룹이 필요한 경우만 검사
        const needsGroupSelection = (this.user.role !== 'manager' && this.filteredGroups.length > 0);
        if (needsGroupSelection && !this.user.group_id) {
            this.errorMessages.group_id = '그룹을 선택해주세요.';
            valid = false;
        }


        return valid;
    }


    public async createUser() {
        if (!this.accountNameChecked) {
            this.errorMessages.account_name = '아이디 중복 확인을 해주세요.';
            await this.service.render();  // 오류 메시지 표시
            return;
        }

        if (!this.validateInputs()) {
            return;
        }

        this.service.loading.show()
        let account_name = this.user.account_name;
        let raw_password = account_name + "1234";
        this.user.password = this.service.auth.hash(raw_password);

        this.user.full_name = `${this.user.first_name} ${this.user.last_name}`;

        let selectedGroupIds: string[] = [];

        if (this.user.role === 'manager') {
            const adminGroup = this.groups.find(group => group.name.includes('_admin') && group.service_id == this.user.community_id);
            if (adminGroup) {
                selectedGroupIds.push(adminGroup.id);
            }
        } else {
            const usersGroup = this.groups.find(group => group.name.endsWith('_users') && group.service_id == this.user.community_id);
            if (usersGroup) {
                selectedGroupIds.push(usersGroup.id);
            }
            if (this.user.group_id) {
                selectedGroupIds.push(this.user.group_id);
            }
        }

        this.user.group_ids = selectedGroupIds;

        const { code } = await wiz.call("create_user", this.user);
        this.service.loading.hide()
        if (code === 200) {
            this.service.alert.success("사용자가 성공적으로 생성되었습니다.");
            await this.router.navigate(['/admin/users']);
        } else {
            this.service.alert.error("사용자 생성에 실패했습니다.");
        }
    }
}

export default PortalGsdcsysadminUserAddComponent;