import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.widget.research.graph');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-gsdcsysuser-widget-research-graph',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.widget.research.graph/view.scss */
/* src/app/portal.gsdcsysuser.widget.research.graph/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserWidgetResearchGraphComponent implements OnInit {
    // @Input() researchs: any;
    public community: any;
    public count: any;
    public series: any;

    @ViewChild('chart')
    public element: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
        await this.buildChart();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        // console.log("researchs : ", this.researchs)
        if (code != 200) return;
        this.community = data.community;
        this.count = data.count;

        this.series = [{ name: '건수', data: this.count }]
        await this.service.render();
    }

    public async buildChart() {
        let options = {
            series: this.series,
            chart: {
                type: 'bar',
                height: 250,
                sparkline: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            colors: ['#246BFA', '#A8BBFC', '#E8EAF6', '#F7BE61', '#7182EA', '#72CCBC', '#F18186'],
            plotOptions: {
                bar: {
                    distributed: true, // 이 옵션으로 개별 막대에 색상 분배 가능
                    borderRadius: 10,
                    columnWidth: '50%',
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            xaxis: {
                categories: this.community,
                labels: {
                    show: true,
                },
                title: {
                    text: "연구커뮤니티",
                },
                tooltip: {
                    enabled: false // X축의 날짜 툴팁 비활성화
                }
            },
            yaxis: {
                labels: {
                    show: true

                },
                title: {
                    text: "건수",
                },
            },
        };

        let chart = new ApexCharts(this.element.nativeElement, options);
        chart.render();
    }
}

export default PortalGsdcsysuserWidgetResearchGraphComponent;