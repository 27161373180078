import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.group.new');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-portal-gsdcsysuser-group-new',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.group.new/view.scss */
/* src/app/portal.gsdcsysuser.group.new/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserGroupNewComponent implements OnInit {
    public groupName: string = '';
    public description: string = '';

    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
    }

    public goBack() {
        this.location.back();
    }

    public async submitGroupRequest() {
        if (!this.groupName || !this.description) {
            await this.service.alert.error("그룹 이름과 설명을 입력해 주세요.");
            return;
        }

        // 확인 메시지 표시
        const confirmed = await this.service.alert.show({
            message: "신규 그룹 생성을 요청하시겠습니까?",
            status: "success",
            action: "확인",
            cancel: "취소"
        });

        // 사용자가 확인 버튼을 클릭한 경우에만 진행
        if (!confirmed) return;
        this.service.loading.show(); // 로딩 시작
        const { code, message } = await wiz.call("submit_group_request", {
            group_name: this.groupName,
            description: this.description
        });
        this.service.loading.hide(); // 로딩 시작

        if (code === 200) {
            await this.service.alert.success("그룹 생성 요청이 성공적으로 제출되었습니다.");
            this.location.back();
        } else {
            await this.service.alert.error(message || "그룹 생성 요청에 실패했습니다.");
        }
    }
}

export default PortalGsdcsysuserGroupNewComponent;