import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.dashboard/view.scss */
/* src/app/page.dashboard/view.scss: no such file or directory */`],
})
export class PageDashboardComponent implements OnInit {

    public isLoading: boolean = false;
    public community_id: any;
    public community: any = {};
    public notice: any = [];
    public ticketing: any = [];
    public research: any = [];
    public manual: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        // this.isLoading = false;
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code != 200) return;
        this.notice = data.notice;
        this.ticketing = data.ticketing;
        this.research = data.research;
        this.manual = data.manual;
        await this.service.render();
        this.isLoading = true;
    }

    public async move(category: any, item: any) {
        await this.service.href(`${category}/${item.id}`);
    }

}

export default PageDashboardComponent;