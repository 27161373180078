import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.faq.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";
import showdown from 'showdown';

@Component({
    selector: 'wiz-portal-gsdcsysuser-faq-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.faq.item/view.scss */
/* src/app/portal.gsdcsysuser.faq.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserFaqItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() faq_id: any;

    // @ViewChild('editor')
    // public editorElement: ElementRef;
    public tags: any = [];

    public item: any = {};
    public editor: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
        // await this.buildEditor();
        await this.service.loading.hide();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.faq_id });
        if (code != 200) return;
        this.item = data.item;
        this.tags = JSON.parse(data.item.tag.replace(/'/g, '"'));
        if (this.item.extra != null) this.item.extra = JSON.parse(this.item.extra.replace(/'/g, '"'));

        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;

        this.editor.data.set(this.item.content);
    }

    public showdown(text) {
        const markdown = typeof text === 'string' ? text : '';
        let converter = new showdown.Converter();
        return converter.makeHtml(markdown);
    }
}

export default PortalGsdcsysuserFaqItemComponent;