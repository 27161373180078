import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.approve.signup');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-approve-signup',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.approve.signup/view.scss */
/* src/app/portal.gsdcsysuser.approve.signup/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserApproveSignupComponent implements OnInit {
    public requests: any[] = [];           // 전체 요청 데이터를 저장하는 배열
    public groupRequests: any[] = [];      // 신규 그룹 생성 요청 데이터를 저장하는 배열
    public filteredRequests: any[] = [];   // 필터링된 요청 데이터를 저장하는 배열
    public pagedRequests: any[] = [];      // 현재 페이지에 해당하는 요청을 저장하는 배열
    public loading: boolean = false;       // 로딩 상태를 나타내는 변수
    public selectedTab: string = 'signup'; // 현재 선택된 탭을 저장하는 변수, 기본값은 'signup'
    public role: string = '';              // 사용자 역할을 저장하는 변수
    public community_id: string = '';      // 커뮤니티 ID 저장

    public pagination: any = {
        current: 1,  // 현재 페이지
        start: 1,    // 시작 페이지
        end: 1       // 총 페이지 수
    };
    
    public search = {
        dump: 10 // 페이지당 요청 수
    };


    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        // 세션 정보에서 역할과 커뮤니티 ID 가져오기
        const session = await wiz.call("get_session");

        this.role = session.data.role;  // session.data.role로 변경
        this.community_id = session.data.community_id;  // session.data.community_id로 변경

        // 요청 데이터를 로드
        await this.loadRequests();
        await this.loadGroupRequests();
    }


    // 탭을 변경하는 함수
    setTab(tab: string) {
        this.selectedTab = tab;     // 선택된 탭을 저장s
        this.filterRequests();      // 탭 변경 시 필터링 함수 호출
    }

    // 요청 데이터를 로드하는 함수
    async loadRequests() {
        this.loading = true; // 로딩 시작
        const { code, data } = await wiz.call('get_pending_requests');
        if (code === 200) {
            this.requests = data
            this.filterRequests(); // 데이터를 받은 후 필터링 함수 호출
        }
        this.loading = false; // 로딩 종료
        await this.service.render();
    }

    // 신규 그룹 생성 요청 데이터 로드
    async loadGroupRequests() {
        const { code, data } = await wiz.call('get_group_requests');
        if (code === 200) {
            this.groupRequests = data;
            this.filterRequests();
        }
    }

    // 선택된 탭에 따라 요청 데이터를 필터링하는 함수
    filterRequests() {
        if (this.selectedTab === 'signup') {
            this.filteredRequests = this.requests.filter(request => request.request_type === 'S');
        } else if (this.selectedTab === 'newGroup') {
            this.filteredRequests = this.groupRequests;
        } else if (this.selectedTab === 'joinGroup') {
            this.filteredRequests = this.requests.filter(request => request.request_type === 'J');
        } else if (this.selectedTab === 'signupWithGroup') {
            this.filteredRequests = this.requests.filter(request => request.request_type === 'N');
        }
        this.calculatePagination(); // 필터링된 데이터 기준으로 페이지네이션 적용
    }

    // 페이지네이션 계산 함수
    calculatePagination() {
        const totalItems = this.filteredRequests.length;
        this.pagination.end = Math.ceil(totalItems / this.search.dump);
        this.changePage(1); // 첫 번째 페이지로 이동
    }

    // 현재 페이지에 해당하는 요청을 업데이트하는 함수
    updatePagedRequests() {
        const startIdx = (this.pagination.current - 1) * this.search.dump;
        const endIdx = startIdx + this.search.dump;
        this.pagedRequests = this.filteredRequests.slice(startIdx, endIdx);
    }

    // 페이지 변경 함수
    changePage(page: number) {
        if (page >= this.pagination.start && page <= this.pagination.end) {
            this.pagination.current = page;
            this.updatePagedRequests();
        }
    }

    // 요청 승인 가능 여부 확인 함수
    async canApprove(request: any, level: string): boolean {
        if (level === 'level_1' && request.level_1 === 'N') {
            return request.group_representative && request.group_representative === this.community_id; // 그룹 대표 승인
        }
        if (level === 'level_2' && request.level_2 === 'N') {
            return request.community_representative && request.community_representative === this.community_id; // 커뮤니티 대표 승인
        }
        if (level === 'level_3' && request.level_3 === 'N') {
            return request.manager && request.manager === this.community_id; // 서비스 담당자 승인
        }
        return false;
    }

    // 승인 처리 함수
    async approveRequest(request_id: string) {
        const isConfirmed = confirm('승인하시겠습니까?');  // 승인 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 중단
        }

        this.service.loading.show(); // 로딩 시작
        const { code } = await wiz.call('approve_request', { request_id });
        this.service.loading.hide(); // 로딩 종료

        if (code === 200) {
            await this.service.alert.success('요청이 승인되었습니다.');
            await this.loadRequests(); // 승인 후 목록을 다시 로드
        } else {
            await this.service.alert.error('승인 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }

    // 거절 처리 함수
    async rejectRequest(request_id: string) {
        const isConfirmed = confirm('거절하시겠습니까?');  // 거절 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 중단
        }

        this.loading = true;  // 로딩 시작
        const { code } = await wiz.call('reject_request', { request_id });
        this.loading = false;  // 로딩 종료

        if (code === 200) {
            await this.service.alert.success('요청이 거절되었습니다.');
            await this.loadRequests(); // 거절 후 목록을 다시 로드
        } else {
            await this.service.alert.error('거절 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }
}

export default PortalGsdcsysuserApproveSignupComponent;