import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.group.join');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-portal-gsdcsysuser-group-join',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.group.join/view.scss */
/* src/app/portal.gsdcsysuser.group.join/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserGroupJoinComponent implements OnInit {
    public selectedGroup: string = '';
    public selectedRepresentative: string = '';
    public availableGroups: any[] = [];
    public availableRepresentatives: any[] = [];
    public loading: boolean = false;

    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        // 현재 사용자의 커뮤니티에 속한 그룹 목록을 불러옵니다
        const { code, data } = await wiz.call("get_available_groups");
        if (code === 200) {
            this.availableGroups = data;
        } else {
            await this.service.alert.error("그룹 목록을 불러오는 중 오류가 발생했습니다.");
        }
    }

    public goBack() {
        this.location.back();
    }

    public async loadRepresentatives() {
        if (!this.selectedGroup) return;

        const { code, data } = await wiz.call("get_group_representatives", { group_id: this.selectedGroup });
        if (code === 200) {
            this.availableRepresentatives = data;
        } else {
            await this.service.alert.error("대표자 목록을 불러오는 중 오류가 발생했습니다.");
        }
    }

    public async submitJoinRequest() {
        if (!this.selectedGroup || !this.selectedRepresentative) {
            await this.service.alert.error("그룹과 대표자를 선택해 주세요.");
            return;
        }

        const confirmed = await this.service.alert.show({
            message: "선택한 그룹에 추가 가입 요청을 하시겠습니까?",
            status: "success",
            action: "확인",
            cancel: "취소"
        });

        if (!confirmed) return;

        this.service.loading.show(); // 로딩 시작
        const { code, message } = await wiz.call("submit_join_request", {
            group_id: this.selectedGroup,
            request_pi: this.selectedRepresentative
        });
        this.service.loading.hide(); // 로딩 종료

        if (code === 200) {
            await this.service.alert.success("그룹 추가 가입 요청이 성공적으로 제출되었습니다.");
            this.location.back();
        } else {
            await this.service.alert.error(message || "그룹 추가 가입 요청에 실패했습니다.");
        }
    }
}

export default PortalGsdcsysuserGroupJoinComponent;