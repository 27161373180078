import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.request.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-request-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.request.list/view.scss */
/* src/app/portal.gsdcsysuser.request.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserRequestListComponent implements OnInit {
    public selectedTab: string = 'signup';         // 현재 선택된 탭, 기본값은 'signup'
    public role: string = '';                      // 사용자 역할 저장
    public community_id: string = '';              // 커뮤니티 ID 저장
    public isIndividualResearcher: boolean = false;// 개인 연구자인지 여부
    public isLoaded: boolean = false;              // 로딩 완료 여부

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        // 세션 정보에서 역할과 커뮤니티 ID 가져오기
        const session = await wiz.call("get_session");
        this.role = session.data.role;
        this.community_id = session.data.community_id;

        // 개인 연구자인지 확인
        await this.checkIndividualResearcher(session.data.id); // 세션에서 가져온 ID를 전달
        // 개인 연구자일 경우 탭을 'newGroup'으로 설정, manager일 경우 'signup'으로 설정
        if (this.isIndividualResearcher && this.role !== 'manager' && this.role !== 'admin') {
            this.selectedTab = 'newGroup';
        } else if (this.role === 'manager' && this.role === 'admin') {
            this.selectedTab = 'signup';
        }


        this.isLoaded = true; // 로딩 완료 표시
        await this.service.render();
    }

    // 개인 연구자 여부 확인
    async checkIndividualResearcher(user_id: string) {
        const { code, data } = await wiz.call("check_individual_researcher", { user_id });
        this.isIndividualResearcher = code === 200 && data.isIndividualResearcher;
    }

    // 탭을 변경하는 함수
    setTab(tab: string) {
        this.selectedTab = tab;
    }
}

export default PortalGsdcsysuserRequestListComponent;