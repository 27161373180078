import '@angular/compiler';
import { PageSupportComponent } from './page.support/page.support.component';
import { PortalGsdcsysadminWidgetUserProfileComponent } from './portal.gsdcsysadmin.widget.user.profile/portal.gsdcsysadmin.widget.user.profile.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalGsdcsysadminCommunityListComponent } from './portal.gsdcsysadmin.community.list/portal.gsdcsysadmin.community.list.component';
import { PortalGsdcsysuserManualListComponent } from './portal.gsdcsysuser.manual.list/portal.gsdcsysuser.manual.list.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalGsdcsysuserBoardListComponent } from './portal.gsdcsysuser.board.list/portal.gsdcsysuser.board.list.component';
import { PortalGsdcsysadminWidgetCommunityGroupInfoComponent } from './portal.gsdcsysadmin.widget.community.group.info/portal.gsdcsysadmin.widget.community.group.info.component';
import { PortalGsdcsysuserTicketingListComponent } from './portal.gsdcsysuser.ticketing.list/portal.gsdcsysuser.ticketing.list.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageReissueOtpComponent } from './page.reissue.otp/page.reissue.otp.component';
import { PortalGsdcsysadminWidgetCommunityTicketingListComponent } from './portal.gsdcsysadmin.widget.community.ticketing.list/portal.gsdcsysadmin.widget.community.ticketing.list.component';
import { PortalGsdcsysuserGroupNewComponent } from './portal.gsdcsysuser.group.new/portal.gsdcsysuser.group.new.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalGsdcsysuserBoardItemComponent } from './portal.gsdcsysuser.board.item/portal.gsdcsysuser.board.item.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalGsdcsysadminWidgetCommunityTicketingItemComponent } from './portal.gsdcsysadmin.widget.community.ticketing.item/portal.gsdcsysadmin.widget.community.ticketing.item.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { PortalGsdcsysuserApproveGroupNewComponent } from './portal.gsdcsysuser.approve.group.new/portal.gsdcsysuser.approve.group.new.component';
import { PortalGsdcsysuserApproveGroupJoinComponent } from './portal.gsdcsysuser.approve.group.join/portal.gsdcsysuser.approve.group.join.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityInfoEditComponent } from './portal.gsdcsysadmin.widget.community.info.edit/portal.gsdcsysadmin.widget.community.info.edit.component';
import { PortalGsdcsysuserWidgetGroupInfoComponent } from './portal.gsdcsysuser.widget.group.info/portal.gsdcsysuser.widget.group.info.component';
import { PortalGsdcsysuserResearchListComponent } from './portal.gsdcsysuser.research.list/portal.gsdcsysuser.research.list.component';
import { PortalGsdcsysuserFaqListComponent } from './portal.gsdcsysuser.faq.list/portal.gsdcsysuser.faq.list.component';
import { PageResetPasswordComponent } from './page.reset.password/page.reset.password.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalGsdcsysuserGroupUserComponent } from './portal.gsdcsysuser.group.user/portal.gsdcsysuser.group.user.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PortalGsdcsysuserGroupItemComponent } from './portal.gsdcsysuser.group.item/portal.gsdcsysuser.group.item.component';
import { PortalGsdcsysadminWidgetCommunityUserComponent } from './portal.gsdcsysadmin.widget.community.user/portal.gsdcsysadmin.widget.community.user.component';
import { ComponentDropdownComponent } from './component.dropdown/component.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityGroupUserComponent } from './portal.gsdcsysadmin.widget.community.group.user/portal.gsdcsysadmin.widget.community.group.user.component';
import { PageGroupIdSubComponent } from './page.group.id.sub/page.group.id.sub.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PageResearchIdComponent } from './page.research.id/page.research.id.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { PortalGsdcsysadminWidgetCommunityGroupItemComponent } from './portal.gsdcsysadmin.widget.community.group.item/portal.gsdcsysadmin.widget.community.group.item.component';
import { PortalGsdcsysadminWidgetCommunitySettingComponent } from './portal.gsdcsysadmin.widget.community.setting/portal.gsdcsysadmin.widget.community.setting.component';
import { PageReissueBothComponent } from './page.reissue.both/page.reissue.both.component';
import { PageUserComponent } from './page.user/page.user.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalGsdcsysuserApproveSignupComponent } from './portal.gsdcsysuser.approve.signup/portal.gsdcsysuser.approve.signup.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalGsdcsysadminRequestListComponent } from './portal.gsdcsysadmin.request.list/portal.gsdcsysadmin.request.list.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalGsdcsysuserWidgetResearchGraphComponent } from './portal.gsdcsysuser.widget.research.graph/portal.gsdcsysuser.widget.research.graph.component';
import { PortalGsdcsysadminFaqListComponent } from './portal.gsdcsysadmin.faq.list/portal.gsdcsysadmin.faq.list.component';
import { PortalGsdcsysadminBoardItemComponent } from './portal.gsdcsysadmin.board.item/portal.gsdcsysadmin.board.item.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { PortalGsdcsysuserGroupListComponent } from './portal.gsdcsysuser.group.list/portal.gsdcsysuser.group.list.component';
import { PageReissuePasswordComponent } from './page.reissue.password/page.reissue.password.component';
import { PortalGsdcsysuserRequestListComponent } from './portal.gsdcsysuser.request.list/portal.gsdcsysuser.request.list.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalGsdcsysadminTicketingListComponent } from './portal.gsdcsysadmin.ticketing.list/portal.gsdcsysadmin.ticketing.list.component';
import { PortalGsdcsysadminWidgetCommunityBoardItemComponent } from './portal.gsdcsysadmin.widget.community.board.item/portal.gsdcsysadmin.widget.community.board.item.component';
import { PortalGsdcsysadminUserAddComponent } from './portal.gsdcsysadmin.user.add/portal.gsdcsysadmin.user.add.component';
import { PortalGsdcsysadminWidgetManualDriveComponent } from './portal.gsdcsysadmin.widget.manual.drive/portal.gsdcsysadmin.widget.manual.drive.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalGsdcsysuserWidgetManualDriveComponent } from './portal.gsdcsysuser.widget.manual.drive/portal.gsdcsysuser.widget.manual.drive.component';
import { PortalGsdcsysadminWidgetCommunityGroupListComponent } from './portal.gsdcsysadmin.widget.community.group.list/portal.gsdcsysadmin.widget.community.group.list.component';
import { PortalGsdcsysadminResearchItemComponent } from './portal.gsdcsysadmin.research.item/portal.gsdcsysadmin.research.item.component';
import { PortalGsdcsysadminManualListComponent } from './portal.gsdcsysadmin.manual.list/portal.gsdcsysadmin.manual.list.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalGsdcsysadminWidgetCommunityGroupSettingComponent } from './portal.gsdcsysadmin.widget.community.group.setting/portal.gsdcsysadmin.widget.community.group.setting.component';
import { PortalGsdcsysuserApproveSignupWgroupComponent } from './portal.gsdcsysuser.approve.signup.wgroup/portal.gsdcsysuser.approve.signup.wgroup.component';
import { PortalGsdcsysadminResearchListComponent } from './portal.gsdcsysadmin.research.list/portal.gsdcsysadmin.research.list.component';
import { PortalGsdcsysuserTicketingItemComponent } from './portal.gsdcsysuser.ticketing.item/portal.gsdcsysuser.ticketing.item.component';
import { PortalGsdcsysadminTicketingItemComponent } from './portal.gsdcsysadmin.ticketing.item/portal.gsdcsysadmin.ticketing.item.component';
import { PageBoardIdComponent } from './page.board.id/page.board.id.component';
import { PageOtpIssuanceComponent } from './page.otp.issuance/page.otp.issuance.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';
import { PortalGsdcsysadminCommunityItemComponent } from './portal.gsdcsysadmin.community.item/portal.gsdcsysadmin.community.item.component';
import { PortalGsdcsysuserGroupJoinComponent } from './portal.gsdcsysuser.group.join/portal.gsdcsysuser.group.join.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PageRequestIdComponent } from './page.request.id/page.request.id.component';
import { PortalGsdcsysadminWidgetCommunityBoardListComponent } from './portal.gsdcsysadmin.widget.community.board.list/portal.gsdcsysadmin.widget.community.board.list.component';
import { PortalGsdcsysuserFaqItemComponent } from './portal.gsdcsysuser.faq.item/portal.gsdcsysuser.faq.item.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalGsdcsysuserResearchItemComponent } from './portal.gsdcsysuser.research.item/portal.gsdcsysuser.research.item.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { PortalGsdcsysuserWidgetGroupUserComponent } from './portal.gsdcsysuser.widget.group.user/portal.gsdcsysuser.widget.group.user.component';
import { PortalGsdcsysadminBoardListComponent } from './portal.gsdcsysadmin.board.list/portal.gsdcsysadmin.board.list.component';
import { PortalGsdcsysadminFaqItemComponent } from './portal.gsdcsysadmin.faq.item/portal.gsdcsysadmin.faq.item.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalGsdcsysadminUserListComponent } from './portal.gsdcsysadmin.user.list/portal.gsdcsysadmin.user.list.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';

const INDEX_PAGE = "dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "support/:sub/:id?",
                component: PageSupportComponent,
                "app_id": "page.support"
            },
            {
                "path": "dashboard",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            },
            {
                "path": "group/:id?/:sub?",
                component: PageGroupIdSubComponent,
                "app_id": "page.group.id.sub"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "research/:id?",
                component: PageResearchIdComponent,
                "app_id": "page.research.id"
            },
            {
                "path": "users",
                component: PageUserComponent,
                "app_id": "page.user"
            },
            {
                "path": "board/:category/:id?",
                component: PageBoardIdComponent,
                "app_id": "page.board.id"
            },
            {
                "path": "community/:category?/:menu?/:id?",
                component: PageCommunityComponent,
                "app_id": "page.community"
            },
            {
                "path": "request/:id",
                component: PageRequestIdComponent,
                "app_id": "page.request.id"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "join",
                component: PageJoinComponent,
                "app_id": "page.join"
            },
            {
                "path": "reissue/otp",
                component: PageReissueOtpComponent,
                "app_id": "page.reissue.otp"
            },
            {
                "path": "reset/password",
                component: PageResetPasswordComponent,
                "app_id": "page.reset.password"
            },
            {
                "path": "reissue/both",
                component: PageReissueBothComponent,
                "app_id": "page.reissue.both"
            },
            {
                "path": "reissue/password",
                component: PageReissuePasswordComponent,
                "app_id": "page.reissue.password"
            },
            {
                "path": "login",
                component: PageLoginComponent,
                "app_id": "page.login"
            },
            {
                "path": "otp/issuance",
                component: PageOtpIssuanceComponent,
                "app_id": "page.otp.issuance"
            }
        ]
    },
    {
        component: LayoutSidebarComponent,
        "children": [
            {
                "path": "admin/:main?/:sub?/:id?/:menu?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }