import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.widget.community.setting');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import { Router } from '@angular/router'
import { marked } from 'marked';

@Component({
    selector: 'wiz-portal-gsdcsysadmin-widget-community-setting',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.widget.community.setting/view.scss */
/* src/app/portal.gsdcsysadmin.widget.community.setting/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminWidgetCommunitySettingComponent implements OnInit, OnDestroy {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    @Input() community: any;

    public async ngOnInit() {
        await this.service.init();
        await this.buildEditor();
    }

    // public async buildEditor() {
    //     let element: any = this.editorElement.nativeElement;¥
    //     let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

    //     this.editor = await ClassicEditor.create(element, {
    //         toolbar: {
    //             items: toolbar,
    //             shouldNotGroupWhenFull: true
    //         },
    //         removePlugins: ["MediaEmbedToolbar"],
    //         table: ClassicEditor.defaultConfig.table,
    //         simpleUpload: {
    //             uploadUrl: '/file/upload'
    //         }
    //     });

    //     this.editor.data.set(this.community.description);

    //     await this.service.render();
    // }
    public async buildEditor() {
        if (this.community.description == null) this.community.description = "";
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            codeBlock: {
                languages: [
                    { language: 'plaintext', label: '평문' },
                    { language: 'c', label: 'C' },
                    { language: 'cpp', label: 'C++' },
                    { language: 'cs', label: 'C#' },
                    { language: 'css', label: 'CSS' },
                    { language: 'diff', label: 'Diff' },
                    { language: 'html', label: 'HTML' },
                    { language: 'java', label: 'Java' },
                    { language: 'javascript', label: 'JavaScript' },
                    { language: 'markdown', label: 'Markdown' }, // 여기서 Markdown 추가
                    { language: 'php', label: 'PHP' },
                    { language: 'python', label: 'Python' },
                    { language: 'ruby', label: 'Ruby' },
                    { language: 'typescript', label: 'TypeScript' },
                    { language: 'xml', label: 'XML' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });
        this.editor.data.set(this.community.description);
    }

    public async ngOnDestroy() {
        // this.community.description = this.editor.data.get();
        this.community.description = this.editor.getData();
    }

    public async update() {
        if(this.tab == 'text') this.changeTab('editor')
        this.community.description = this.editor.getData();
        if (this.community.name == "") return await this.service.alert.error("연구커뮤니티 명을 입력해주세요.");
        else if (!('regist_pi' in this.community)) return await this.service.alert.error("PI 승인 절차를 선택해주세요.");
        else if (!('regist_user' in this.community)) return await this.service.alert.error("개인 연구자 승인 절차를 선택해주세요.");
        const { code } = await wiz.call("update", this.community);
        if (code != 200) await this.service.alert.error("오류가 발생했습니다.");
        else {
            let res = await this.service.alert.success("저장되었습니다.");
            if (res && !('id' in this.community)) await this.router.navigate(['/admin/community']);
        }
        await this.service.render();
    }

    public tab = "editor";
    public changeTab(tab) {
        if (tab === "editor") {
            this.editor.data.set(this.community.description);
        }
        this.tab = tab;
        this.service.render();
    }
}

export default PortalGsdcsysadminWidgetCommunitySettingComponent;