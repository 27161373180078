import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.approve.group.join');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-approve-group-join',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.approve.group.join/view.scss */
/* src/app/portal.gsdcsysuser.approve.group.join/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserApproveGroupJoinComponent implements OnInit {
    public requests: any[] = [];           // 전체 요청 데이터를 저장하는 배열
    public joinRequests: any[] = [];       // 그룹 추가 가입 요청 데이터를 저장하는 배열
    public filteredRequests: any[] = [];   // 필터링된 요청 데이터를 저장하는 배열
    public loading: boolean = false;       // 로딩 상태를 나타내는 변수
    public role: string = '';              // 사용자 역할을 저장하는 변수
    public community_id: string = '';      // 커뮤니티 ID 저장

    constructor(@Inject( Service) public service: Service) { }

    public pagination: any = {
        current: 1,  // 현재 페이지
        start: 1,    // 시작 페이지
        end: 1       // 총 페이지 수
    };

    public search = {
        dump: 10 // 페이지당 요청 수
    };

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        // 세션 정보에서 역할과 커뮤니티 ID 가져오기
        const session = await wiz.call("get_session");
        this.role = session.data.role;
        this.community_id = session.data.community_id;

        // 요청 데이터를 로드
        await this.loadJoinRequests();
    }

    // 그룹 추가 가입 요청 데이터를 로드하는 함수
    async loadJoinRequests() {
        this.loading = true; // 로딩 시작
        const { code, data } = await wiz.call('get_join_requests');
        if (code === 200) {
            this.joinRequests = data;
            this.filteredRequests = this.joinRequests; // 필터링된 요청 데이터 설정
            this.calculatePagination();
        }
        this.loading = false; // 로딩 종료
        await this.service.render();
    }

    calculatePagination() {
        const totalRequests = this.joinRequests.length;
        this.pagination.end = Math.ceil(totalRequests / this.search.dump); // 총 페이지 수 설정
        this.changePage(1); // 첫 페이지로 초기화
    }

    updatePagedRequests() {
        const startIdx = (this.pagination.current - 1) * this.search.dump;
        const endIdx = startIdx + this.search.dump;
        this.filteredRequests = this.joinRequests.slice(startIdx, endIdx);
    }

    changePage(page: number) {
        if (page >= this.pagination.start && page <= this.pagination.end) {
            this.pagination.current = page;
            this.updatePagedRequests();
        }
    }

    // 요청 승인 처리 함수
    async approveRequest(request_id: string) {
        const isConfirmed = confirm('승인하시겠습니까?');  // 승인 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 중단
        }

        this.service.loading.show(); // 로딩 시작
        const { code } = await wiz.call('approve_join_request', { request_id });
        this.service.loading.hide(); // 로딩 종료

        if (code === 200) {
            await this.service.alert.success('요청이 승인되었습니다.');
            await this.loadJoinRequests(); // 승인 후 목록을 다시 로드
        } else {
            await this.service.alert.error('승인 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }

    // 요청 거절 처리 함수
    async rejectRequest(request_id: string) {
        const isConfirmed = confirm('거절하시겠습니까?');  // 거절 확인창
        if (!isConfirmed) {
            return; // 사용자가 취소한 경우 중단
        }

        this.loading = true;  // 로딩 시작
        const { code } = await wiz.call('reject_join_request', { request_id });
        this.loading = false;  // 로딩 종료

        if (code === 200) {
            await this.service.alert.success('요청이 거절되었습니다.');
            await this.loadJoinRequests(); // 거절 후 목록을 다시 로드
        } else {
            await this.service.alert.error('거절 중 오류가 발생했습니다.');
        }
        await this.service.render();
    }
}

export default PortalGsdcsysuserApproveGroupJoinComponent;