import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.widget.community.group.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-widget-community-group-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.widget.community.group.item/view.scss */
/* src/app/portal.gsdcsysadmin.widget.community.group.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminWidgetCommunityGroupItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() group_id: any;
    @Input() community_id: any;
    @Input() menu: any;
    // @Input() community: any;

    public group: any = null;
    // public community: any = null;
    public community: any = {
        id: "",
        name: ""
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        // if (this.group_id == "new") {
        //     this.group = { name: "", description: "" };
        //     await this.service.render();
        //     return;
        // }
        const { code, data } = await wiz.call("info", { id: this.group_id, community:this.community_id });
        this.group = data.info;
        this.community = data.community;
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public linkGroupMenu(menu: string) {
        return `/admin/community/${this.community.id}/${this.group_id}/${menu}`;
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-orange-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-orange-600";
    }
}

export default PortalGsdcsysadminWidgetCommunityGroupItemComponent;