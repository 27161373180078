import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.loading.season');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-season-loading-season',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.season.loading.season/view.scss */
img {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}`],
})
export class PortalSeasonLoadingSeasonComponent implements OnInit {
    @Input() height: number = 36;

    constructor() { }

    public async ngOnInit() {
    }
}

export default PortalSeasonLoadingSeasonComponent;