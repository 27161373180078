import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.faq.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-faq-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.faq.list/view.scss */
/* src/app/portal.gsdcsysadmin.faq.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminFaqListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() ticketing_id: any;
    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1, text: '' };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/landing");
        await this.load(this.search.page);
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        for (let item of this.items) {
            item.tag = JSON.parse(item.tag.replace(/'/g, '"'));
        }
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async move(item) {
        await this.service.href(`/admin/faq/${item.id}`);
    }

}

export default PortalGsdcsysadminFaqListComponent;