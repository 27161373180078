import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.reissue.both');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';

@Component({
    selector: 'wiz-page-reissue-both',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.reissue.both/view.scss */
/* src/app/page.reissue.both/view.scss: no such file or directory */`],
})
export class PageReissueBothComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public data: any = {
        account_name: '',
        email: '',
        onetimepass: '',
        verify: false,
        verified: false
    };

    public errorMessages: any = {};

    public async ngOnInit() {
        await this.service.init();
    }

    // 이메일 인증 코드 발송
    public async sendEmailVerification() {
        const { code } = await wiz.call("emailAuthentication", {
            account_name: this.data.account_name,
            email: this.data.email
        });
        if (code === 200) {
            this.data.verify = true;
            await this.service.alert.success("이메일 인증 코드가 발송되었습니다.");
        } else {
            await this.alert("이메일 인증 코드 발송에 실패했습니다.", "error");
        }
    }

    // 비밀번호 및 OTP 재발급
    public async verifyAndReissueBoth() {
        this.service.loading.show(); // 로딩 시작
        const { code } = await wiz.call("verifyAndReissueBoth", {
            account_name: this.data.account_name,
            email: this.data.email,
            onetimepass: this.data.onetimepass
        });
        this.service.loading.hide(); // 로딩 종료
        if (code === 200) {
            await this.service.alert.success("비밀번호와 OTP가 초기화되었습니다. 초기 비밀번호로 로그인 후 OTP를 새로 발급받으세요.");
            this.router.navigate(['/login']); // 로그인 화면으로 리다이렉트
        } else {
            await this.alert("인증 코드가 유효하지 않거나 재설정에 실패했습니다.", "error");
        }
    }

    // 알림 창 함수
    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }
}

export default PageReissueBothComponent;