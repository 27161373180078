import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.board.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";
import showdown from 'showdown';

@Component({
    selector: 'wiz-portal-gsdcsysuser-board-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysuser.board.item/view.scss */
/* src/app/portal.gsdcsysuser.board.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserBoardItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() board_id: any;
    @Input() category: any;

    @ViewChild('editor')
    public editorElement: ElementRef;
    @ViewChild('editor2')
    public editorElement2: ElementRef;
    public comments: any = [];
    public comment_text: any = '';

    public item: any = {};
    public editor: any;

    public target_user: any = null;

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
        if (this.board_id == 'new') await this.buildEditor();
        else await this.buildEditor2();
        await this.service.loading.hide();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.board_id });
        if (code != 200) return;

        this.item = data.item;
        this.comments = data.comments;

        await this.service.render();

        this.item.created = moment(this.item.created).format("YYYY-MM-DD");
        this.item.updated = moment(this.item.updated).format("YYYY-MM-DD");

        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        this.editor.data.set(this.item.content);
    }

    public async buildEditor2() {
        let editor2 = this.editor2 = await ClassicEditor.create(this.editorElement2.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        this.editor2.data.set(this.comment_text);
    }

    public showdown(text) {
        const markdown = typeof text === 'string' ? text : '';
        let converter = new showdown.Converter();
        return converter.makeHtml(markdown);
    }

    public async sendComment() {
        this.comment_text = this.editor2.data.get();
        if (!this.comment_text) return await this.service.alert.error("내용을 입력해주세요.");
        await wiz.call("comment", { board_id: this.board_id, text: this.comment_text, category: this.category });
        this.comment_text = "";
        await this.service.render();
        await this.load();
        this.editor2.data.set(this.comment_text);
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await wiz.call("remove_comment", comment);
        await this.load();
    }

}

export default PortalGsdcsysuserBoardItemComponent;