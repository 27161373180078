import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.otp.issuance');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-otp-issuance',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.otp.issuance/view.scss */
/* src/app/page.otp.issuance/view.scss: no such file or directory */`],
})
export class PageOtpIssuanceComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public qrCodeImage: string | null = null;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

    public async issueOtp() {
        this.service.loading.show();  // 로딩 시작

        let { code, data } = await wiz.call("issue_otp");

        this.service.loading.hide();  // 로딩 종료

        if (code === 200) {
            this.qrCodeImage = data.qr_code_image;
            await this.alert("OTP가 성공적으로 발급되었습니다.", "success");
        } else {
            await this.alert(data, "error");
        }
    }

    public async logout() {
        await wiz.call("logout");
        location.href = "/login";
    }
}

export default PageOtpIssuanceComponent;