import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.manual.list');
import { OnInit } from "@angpllar/core";
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Dizest } from "src/libs/portal/dizest/dizest";

import DefaultEditor from "src/app/portal.dizest.editor.default/portal.dizest.editor.default.component";
import ImageEditor from "src/app/portal.dizest.editor.image/portal.dizest.editor.image.component";

import showdown from 'showdown';
import { KeyMod, KeyCode } from 'monaco-editor';

@Component({
    selector: 'wiz-portal-gsdcsysadmin-manual-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.gsdcsysadmin.manual.list/view.scss */
/* src/app/portal.gsdcsysadmin.manual.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminManualListComponent implements OnInit {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public workspace: any;
    public shortcuts: any = [];

    public monaco: any = {
        value: '',
        language: 'python',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }
    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public ref: ViewContainerRef    ) {
        this.dizest = new Dizest(service);

        this.dizest.editorType.bind({
            cls: ImageEditor,
            trigger: (ext, node) => {
                if (['png', 'jpg', 'jpeg', 'gif', 'ico', 'icon'].includes(ext)) {
                    return true;
                }
            }
        });

        this.dizest.editorType.bind({
            cls: DefaultEditor,
            trigger: (ext, node) => {
                return true;
            }
        });

        this.shortcuts.push({
            name: "save",
            key: ["cmd + s", "ctrl + s"],
            monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
            preventDefault: true,
            command: async () => {
                await this.save();
            }
            // }, {
            //     key: ["alt + w"],
            //     preventDefault: true,
            //     command: async () => {
            //         if (!this.workspace.selected) return;
            //         if (this.workspace.selected.closeWindow)
            //             await this.workspace.selected.closeWindow();
            //         else
            //             await this.workspace.selected.close();
            //     }
            // }, {
            //     key: ["shift + enter"],
            //     preventDefault: true,
            //     command: async () => {
            //         if (!this.workspace.selected) return;
            //         if (this.workspace.selected.run)
            //             await this.workspace.selected.run();
            //     }
            // }, {
            //     key: ["esc"],
            //     preventDefault: true,
            //     command: async () => {
            //         if (!this.workspace.selected) return;
            //         if (this.workspace.selected.esc)
            //             await this.workspace.selected.esc();
            //     }
        });
    }
    public viewtypes: any;
    public async ngOnInit() {
        await this.service.init();
        await this.dizest.kernels();
        await this.dizest.loadConfig();
        await this.service.render();

        let extension: any = "md";
        this.viewtypes = {
            'sql': { language: 'sql' },
            'md': { language: 'markdown' },
            'ts': { language: 'typescript', renderValidationDecorations: 'off' },
            'js': { language: 'javascript' },
            'css': { language: 'css' },
            'scss': { language: 'scss' },
            'json': { language: 'json' },
            'pug': { language: 'pug' },
            'py': { language: 'python' }
        };

        this.monaco = {
            value: '',
            language: 'markdown',
            theme: "vs",
            fontSize: 14,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            wordWrap: true,
            roundedSelection: false,
            glyphMargin: false,
            readOnly: false,
            scrollbar: {
                handleMouseWheel: true,
            },
            minimap: {
                enabled: false
            }
        };

        if (this.viewtypes[extension])
            for (let key in this.viewtypes[extension])
                this.monaco[key] = this.viewtypes[extension][key];

        await this.service.render();
    }

    public async init(e: any) {
        if (this.shortcuts) {
            for (let i = 0; i < this.shortcuts.length; i++) {
                let shortcut = this.shortcuts[i];
                e.editor.addCommand(shortcut.monaco, shortcut.command);
            }
        }
    }

    public filter: any;
    public content: string = "";
    public descriptionEditable: boolean = false;
    public tabs: any = [];
    public filename: any;

    public onContentChanged(event: any) {
        this.content = event; // 변경된 내용을 tab에 반영
        this.service.render();
    }

    public async toggleEditable() {
        this.descriptionEditable = !this.descriptionEditable;
        await this.service.render();
    }
    public showdown(text) {
        const markdown = typeof text === 'string' ? text : '';
        let converter = new showdown.Converter();
        return converter.makeHtml(markdown);
    }

    public tabInfo: any = [];
    public current: any = {};
    public mode: any;
    public async info(e) {
        let filter = JSON.stringify(e);
        const { code, data } = await wiz.call("load", { filter: filter });
        if (code == 200) {
            this.content = data.data;
            this.filename = data.name;
            this.mode = data.mode;
            
            if (this.viewtypes[this.mode])
                for (let key in this.viewtypes[this.mode])
                    this.monaco[key] = this.viewtypes[this.mode][key];

            if (!this.tabs.includes(this.filename)) this.tabs.push(this.filename);
            if (!this.tabInfo.some(item => item.name === this.filename)) this.tabInfo.push({ "name": this.filename, "content": this.content, "mode": this.mode });
            this.current = { "name": this.filename, "content": this.content, "mode": this.mode };
            await this.service.render();
        }
    }

    public async change(tab: any) {
        this.current = tab;
        this.descriptionEditable = false;
        await this.service.render();
    }

    public async close(tab: any) {
        this.tabs = this.tabs.filter(tag => tag !== tab.name);
        this.tabInfo = this.tabInfo.filter(item => item.name !== tab.name);
        this.descriptionEditable = false;
        if (this.tabInfo.length > 0) {
            this.current = this.tabInfo[this.tabInfo.length - 1];
        } else {
            this.current = {}; // 배열이 비어 있을 경우 처리
        }
        await this.service.render();
    }

    public async save() {
        const { code, data } = await wiz.call("save", this.current);
        if (code != 200) return;
        await this.service.toast.success("저장되었습니다.");
    }

    public workspaceSortableOption: any = {
        animation: 0,
        handle: '.view-tab'
    };

    public editorLoading: boolean = false;

    public driveConfig: any = {
        open: async (node, ext) => {
            this.editorLoading = true;
            await this.service.render();
            let opts: any = this.dizest.editorType.select(ext, node);
            opts.extension = ext;
            // await this.workspace.open(node.id, opts);
            await this.dizest.kernels();
            this.editorLoading = false;
            await this.service.render();
            return true;
        }
    };

    public isHideDrive: boolean = false;
}

export default PortalGsdcsysadminManualListComponent;